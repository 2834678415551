import React from "react"
import { graphql } from "gatsby"

import Layout from "components/layout"
import SEO from "components/seo"
import { Wysiwyg } from "components/helpers"

import "scss/faq.scss"

const Faq = ({ data }) => {
  if (!data) return null

  const {
    nodeFaqs: {
      title,
      field_description,
      relationships: { field_faqs },
    },
  } = data

  const metadata = {
    url: "/faq",
    title: title,
    description: field_description?.processed,
    image: require("../images/enovation-bkg.webp"),
  }

  return (
    <Layout>
      <SEO {...metadata} />
      <div className="header-blue">
        <div className="faq ">
          <div className="faq__bkg"></div>
          <div className="container">
            <h1
              className="title--wavy"
              data-sal-duration="1000"
              data-sal="zoom-in"
            >
              {title}
            </h1>
            <Wysiwyg
              className="faq__description"
              data-sal-duration="1000"
              data-sal="zoom-in"
              {...field_description}
            />
            {field_faqs.map(
              ({ id, field_title, relationships: { field_faq } }) => (
                <div
                  className="accordion"
                  data-sal-duration="1000"
                  data-sal="zoom-in"
                  key={id}
                >
                  <h2 className="accordion__heading">{field_title}</h2>
                  {field_faq.map(
                    ({ id: faq_id, field_question, field_answer }) => (
                      <details className="accordion__detail" key={faq_id}>
                        <summary className="accordion__summary">
                          <h3 className="accordion__question">
                            {field_question}
                          </h3>
                        </summary>
                        <Wysiwyg
                          className="accordion__answer"
                          {...field_answer}
                        />
                      </details>
                    )
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Faq

export const query = graphql`
  query FaqPageQuery($locale: String = "fr") {
    nodeFaqs(
      langcode: { eq: $locale }
      path: { alias: { eq: "/faq" } }
    ) {
      title
      field_description {
        processed
      }
      relationships {
        field_faqs {
          id
          field_title
          relationships {
            field_faq {
              id
              field_question
              field_answer {
                processed
              }
            }
          }
        }
      }
    }
  }
`
